import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)},
  {
    path: 'details',
    loadChildren: () => import('./details/details.module').then(m => m.DetailsPageModule)
  },
  {
    path: 'editor/start',
    loadChildren: () => import('./editor/editor-start/editor-start.module').then(m => m.EditorStartPageModule)
  },
  {
    path: 'editor/slider',
    loadChildren: () => import('./edit-slider/edit-slider.module').then(m => m.EditSliderPageModule)
  },
  {
    path: 'editor/slider/:idSlider/card',
    loadChildren: () => import('./edit-slider-card/edit-slider-card.module').then( m => m.EditSliderCardPageModule)
  },
  {
    path: 'select-image',
    loadChildren: () => import('./select-image/select-image.module').then( m => m.SelectImagePageModule)
  },
  {
    path: 'crop-image',
    loadChildren: () => import('./crop-image/crop-image.module').then( m => m.CropImagePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
